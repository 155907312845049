
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import InputItem from '@/components/InputItem.vue'
import smsMixin from '@/mixins/sms'
import { codeRegExp, emailRegExp } from '@/utils/reg'
import GlobalMixin from '@/mixins/global'
import randomString from '@/utils/random'
import { getGoBackUrl, removeGoBackUrl } from '@/utils/gobackurl'
import configs from '@/configs'
const CryptoJS = require('crypto-js')

@Component({
  name: 'passportFindAccount',
  components: {
    'input-item': InputItem
  },
  mixins: [smsMixin, GlobalMixin]
})
export default class PassportFindAccountPage extends Vue {
  @Provide() public step: number = 0
  @Provide() public old: string = ''
  @Provide() public oldAccount: string = ''
  @Provide() public account: string = ''
  @Provide() public token: string = ''
  @Provide() public code: number = 0
  @Provide() public apiError: string = ''
  @Provide() public waitTime: number = 2
  @Provide() public load: boolean = true

  @Emit()
  public created () {
    this.refresh()
  }

  @Emit()
  public async refresh () {
    try {
      const res = await httpHelper.get({
        url: 'IAccount/BindEmail',
        type: 'apiv5'
      })
      this.old = res.data
    } catch (err) {
      this.showErrorToast(err)
    }
  }
  @Emit()
  public async firstStep () {
    this.apiError = this.mobileError(this.oldAccount)
    if (this.apiError) {
      return
    }
    let data: any = {
    }
    let iv = randomString(16)
    const time = Math.round(Date.now() / 1000)
    const message = `${this.oldAccount}|${time}`
    const key = CryptoJS.enc.Utf8.parse('k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav')
    data.salt = iv
    iv = CryptoJS.enc.Utf8.parse(iv)
    const encrypted = CryptoJS.AES.encrypt(message,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })
    data.oldEmail = encrypted.toString()
    try {
      const res = await httpHelper.post({
        url: 'IAccount/ValidateEmail',
        data,
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.token = res.data.token
        this.step += 1
      }
    } catch (err) {
      this.showErrorToast(err)
    }
  }
  @Emit()
  public async sendCode () {
    this.apiError = this.mobileError(this.account)
    if (this.apiError) {
      return
    }
    let data: any = {
      EmailType: 4
    }
    let iv = randomString(16)
    const time = Math.round(Date.now() / 1000)
    const message = `${this.account}|${time}`
    const key = CryptoJS.enc.Utf8.parse('k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav')
    data.salt = iv
    iv = CryptoJS.enc.Utf8.parse(iv)
    const encrypted = CryptoJS.AES.encrypt(message,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })
    data.email = encrypted.toString()
    try {
      const res = await httpHelper.post({
        url: 'SMS/EmailCode',
        data,
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.$toasted.show('邮件发送成功！')
      }
    } catch (err) {
      this.showErrorToast(err)
    }
  }

  @Emit()
  public async submit () {
    this.apiError = this.mobileError(this.account) || this.codeError()
    if (this.apiError) {
      return
    }
    if (!this.load) {
      return
    }
    this.load = false
    let data: any = {
      code: this.code,
      token: this.token
    }
    let iv = randomString(16)
    const time = Math.round(Date.now() / 1000)
    const message = `${this.account}|${time}`
    const key = CryptoJS.enc.Utf8.parse('k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav')
    data.salt = iv
    iv = CryptoJS.enc.Utf8.parse(iv)
    const encrypted = CryptoJS.AES.encrypt(message,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })
    data.email = encrypted.toString()
    try {
      const res = await httpHelper.post({
        url: 'IAccount/BindEmail',
        data,
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.$toasted.show('邮箱设置成功！' + this.waitTime + '秒钟后自动返回首页').goAway(3000)
        this.getWaitTime()
      }
    } catch (err) {
      this.showErrorToast(err)
      this.load = true
    }
  }
  @Emit()
  public getWaitTime () {
    const self = this
    if (this.waitTime > 0) {
      setTimeout(() => {
        self.waitTime--
        self.getWaitTime()
      }, 1000)
    } else {
      let url = getGoBackUrl()
      if (document.referrer.includes('/user/index') || document.referrer.includes('userHome')) {
        console.log('goback2')
        location.replace(httpHelper.mRoot)
      } else if (document.referrer.includes(`${configs.passportHost}/authorization`)) {
        console.log('goback3')
        history.go(-2)
      } else if (url.includes('/user/index') || url.includes('userHome')) {
        console.log('goback6')
        removeGoBackUrl()
        location.replace(httpHelper.mRoot)
      } else if (url) {
        console.log('goback5')
        removeGoBackUrl()
        location.replace(url)
      } else {
        console.log('goback4')
        history.back()
      }
    }
  }
  @Emit()
  public mobileError (account: string) {
    if (!account) {
      return '邮箱不能为空'
    }
    return emailRegExp.test(account) ? '' : '您输入的邮箱格式不正确，请重新输入'
  }
  @Emit()
  public codeError () {
    if (!this.code) {
      return '验证码不能为空'
    }
    return codeRegExp.test(String(this.code)) ? '' : '您输入的验证码格式不正确，请重新输入'
  }

  @Emit()
  public setOldAccount (detail: any) {
    this.oldAccount = detail.value
  }

  @Emit()
  public setAccount (detail: any) {
    this.account = detail.value
  }

  @Emit()
  public setCode (detail: any) {
    this.code = detail.value
  }

  @Emit()
  public goback () {
    switch (this.step) {
      case 0:
        history.back()
        break
      case 1:
        this.step = 0
        break
      default:
        history.back()
    }
  }

  @Emit()
  public unbind () {
    this.apiError = this.mobileError(this.oldAccount)
    if (this.apiError) {
      return
    }
    let message = {
      title: '',
      body: '您确定解绑安全邮箱？解绑后您将无法接收邮件通知。'
    }
    let options = {
      html: true,
      okText: '确定解绑',
      cancelText: '取消'
    }
    this.$dialog.confirm(message, options)
      .then(() => {
        this.confirmUnBind()
      })
      .catch(() => {})
  }
  @Emit()
  public async confirmUnBind () {
    let data: any = {
    }
    let iv = randomString(16)
    const time = Math.round(Date.now() / 1000)
    const message = `${this.oldAccount}|${time}`
    const key = CryptoJS.enc.Utf8.parse('k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav')
    data.salt = iv
    iv = CryptoJS.enc.Utf8.parse(iv)
    const encrypted = CryptoJS.AES.encrypt(message,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })
    data.email = encrypted.toString()
    try {
      const res = await httpHelper.post({
        url: 'IAccount/UnbindSafeEmail',
        data,
        type: 'apiv5'
      })
      if (res.error === '0') {
        this.$toasted.show('邮箱解绑成功！' + this.waitTime + '秒钟后自动返回首页').goAway(3000)
        this.getWaitTime()
      }
    } catch (err) {
      this.showErrorToast(err)
    }
  }
}
